import { FC, SyntheticEvent, useState } from 'react'
import styles from './Table.module.scss'
import { RecognizeStatsItem } from 'components/BboxedImage/BboxImage'
import { ITaskResultValues } from '../../../OcrMultiple'
import classNames from 'classnames'

interface ITableCell extends RecognizeStatsItem {
    row: number
    col: number
}

function extractRow(str) {
    const rowPattern = /row-(\d+)/;
    const match = str.match(rowPattern);
    return parseInt(match ? match[1] : undefined);
}

function extractCol(str) {
    const colPattern = /col-(\d+)/;
    const match = str.match(colPattern);
    return parseInt(match ? match[1] : undefined);
}

export const Table: FC<{
    valuesToEdit: ITaskResultValues
    handleChange: (e: SyntheticEvent, label: string, isEdited?: boolean) => void
    table: RecognizeStatsItem[]
    onHover: (item: string | null) => void
}> = ({ table: rawTableData, onHover, valuesToEdit, handleChange }) => {
    let maxCol = 0
    let maxRow = 0

    const [focusedTd, setFocusedTd] = useState(null)

    const handleFocus = (id: string) => {
        setFocusedTd(id)
    }

    const handleBlur = () => {
        setFocusedTd(null)
    }

    const cells = rawTableData.reduce(
        (cells: ITableCell[], cell: RecognizeStatsItem) => {
            const col = extractCol(cell.label);
            if (col > maxCol) maxCol = col

            const row = extractRow(cell.label);
            if (row > maxRow) maxRow = row

            cells.push({ ...cell, col, row })
            return cells
        },
        [],
    )

    const tableData = Array.from({ length: maxRow + 1 }, () =>
        Array(maxCol + 1).fill(null),
    )

    cells.forEach((cell: ITableCell) => {
        tableData[cell?.row][cell?.col] = cell
    })

    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <tbody>
                    {tableData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, colIndex) => {
                                const isEdited =
                                    cell?.value !== valuesToEdit[cell?.label]?.value
                                return (
                                    <td
                                        className={classNames(
                                            styles.tableData,
                                            cell?.id === focusedTd && styles.focusedTd,
                                            isEdited && styles.editedTd,
                                            cell?.isLowConfidence && styles.isLowConfidence,

                                        )}
                                        key={colIndex}
                                    >
                                        {cell ? (
                                            <>
                                                <div className={styles.widthSetter}>
                                                    {valuesToEdit[cell.label].value}
                                                </div>
                                                <input
                                                    className={styles.cellInput}
                                                    value={valuesToEdit[cell.label].value}
                                                    onChange={(e) => {
                                                        handleChange(e, cell.label, isEdited)
                                                    }}
                                                    onFocus={() => {
                                                        handleFocus(cell.id)
                                                    }}
                                                    onBlur={handleBlur}
                                                    onMouseEnter={() => onHover(cell.id)}
                                                    onMouseLeave={() => onHover(null)}
                                                ></input>
                                            </>
                                        ) : null}
                                    </td>
                                )
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
